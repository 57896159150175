import React, { createContext, useContext, useEffect, useState } from "react";
import { setCookies, getCookies, removeCookies } from "@/context/utilities";
import { useRouter } from "next/router";

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const router = useRouter();
  useEffect(() => {
    const get = getCookies("user_token");
    if (get?.email) {
      setUser(get);
    } else {
      router.push("/");
    }
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const register = async (userData) => {
    setLoading(true);
    try {
      const response = await fetch("/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (response.ok) {
        setUser(userData);
        const date = new Date();
        date.setDate(date.getDate() + 30);
        setCookies("user_token", userData, date);
        router.push("/student/student-dashboard");
        setError(null);
      } else {
        setLoading(false);
        setError(data.message || "An error occurred during registration.");
      }
    } catch (err) {
      console.error("Registration error:", err);
      setLoading(false);
      setError("Registration failed. Please try again later.");
    } finally {
    }
  };
  const login = async (loginData) => {
    setLoading(true);
    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      const data = await response.json();
      if (response.ok) {
        setUser(data.user);
        if (loginData["remember me"]) {
          const date = new Date();
          date.setDate(date.getDate() + 30);
          setCookies("user_token", data.user, date);
        } else {
          const date = new Date();
          date.setMinutes(date.getMinutes() + 30);
          setCookies("user_token", data.user, date);
        }
        router.push("/student/student-dashboard");
        setError(null);
      } else {
        setError(data.message);
        setLoading(false);
      }
    } catch (err) {
      setError("Login failed");
      setLoading(false);
    } finally {
    }
  };

  const logout = () => {
    setUser(null);
    removeCookies("user_token");
    router.push("/");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        setError,
        register,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
