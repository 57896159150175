import { DateTime } from "luxon";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const camelCaseToDashed = (string) => {
  const dashedString = string.replace(/\s+/g, "-").toLowerCase();
  return dashedString;
};

const flatDeep = (arr, d = 1) =>
  d > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
        []
      )
    : arr.slice();

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace("&", "and") // Replace spaces with -
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

const containsObject = (obj, list) => {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i].slug === obj.slug) {
      return i;
    }
  }

  return -1;
};

const getCategories = (blogs) => {
  let allPosts = blogs.map((item) => item.postData),
    cats = allPosts.map((item) => item.categories),
    singleCatArray = flatDeep(cats),
    categories = [];

  singleCatArray.forEach((cat) => {
    const obj = {
        title: cat.trim(),
        slug: slugify(cat),
        count: 1,
      },
      objIndex = containsObject(obj, categories);

    if (objIndex !== -1) {
      const prevCount = categories[objIndex].count;

      categories[objIndex] = {
        title: cat.trim(),
        slug: slugify(cat),
        count: prevCount + 1,
      };
    } else {
      categories.push(obj);
    }
  });

  return categories;
};

const getTags = (blogs) => {
  let allPosts = blogs.map((item) => item.postData),
    allTags = allPosts.map((item) => item.tags),
    singleTagArray = flatDeep(allTags),
    tags = [];

  singleTagArray.forEach((tag) => {
    const obj = {
        title: tag.trim(),
        slug: slugify(tag),
        count: 1,
      },
      objIndex = containsObject(obj, tags);

    if (objIndex !== -1) {
      const prevCount = tags[objIndex].count;

      tags[objIndex] = {
        title: tag.trim(),
        slug: slugify(tag),
        count: prevCount + 1,
      };
    } else {
      tags.push(obj);
    }
  });

  return tags;
};

const sortingByDate = function (posts) {
  return posts.sort((a, b) => {
    const beforeDate = DateTime.fromFormat(
      a.postData.publishedAt,
      "LLL dd yyyy"
    );
    const afterDate = DateTime.fromFormat(
      b.postData.publishedAt,
      "LLL dd yyyy"
    );
    return afterDate - beforeDate;
  });
};

const sortingByLikes = function (posts) {
  return posts.sort(
    (a, b) => parseFloat(a.postData.likes) - parseFloat(b.postData.likes)
  );
};

const getCourseCategories = (courses) => {
  let allCourses = courses.map((item) => item),
    cats = allCourses.map((item) => item.courseCategory),
    singleCatArray = flatDeep(cats),
    categories = [];

  singleCatArray.forEach((cat) => {
    const obj = {
        title: cat.trim(),
        slug: slugify(cat),
        count: 1,
      },
      objIndex = containsObject(obj, categories);

    if (objIndex !== -1) {
      const prevCount = categories[objIndex].count;

      categories[objIndex] = {
        title: cat.trim(),
        slug: slugify(cat),
        count: prevCount + 1,
      };
    } else {
      categories.push(obj);
    }
  });

  return categories;
};

const toggleShowMore = (e) => {
  const button = e.target,
    parent = button.parentElement;

  e.stopPropagation();

  button.classList.toggle("active");
  parent.classList.toggle("active");

  if (button.classList.contains("active")) {
    button.innerText = "Show Less";
  } else {
    button.innerText = "Show More";
  }
};

function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

const inputValueChange = (e, key, field, state, setState) => {
  if (key === "multiple") {
    const parent = field.split(".")[0];
    const child = field.split(".")[1].toLowerCase();
    const index = field.split(".")[2];
    let array = state[parent] ? [...state[parent]] : [{}];
    if (!array[index]) {
      array[index] = {};
    }
    array[index][child] = e.target.value;
    setState({
      ...state,
      [parent]: array,
    });
  } else if (key === "checkbox" || key === "file") {
    const value = e.target.value;
    const checked = e.target.checked;

    setState((prevState) => {
      if (key === "checkbox") {
        let newArray = prevState[field] ? [...prevState[field]] : [];
        if (checked) {
          newArray.push(value);
        } else {
          newArray = newArray.filter((item) => item !== value);
        }
        return { ...prevState, [field]: newArray };
      } else {
        return {
          ...prevState,
          [field]: key === "file" ? e.target.files[0] : e.target.value,
        };
      }
    });
  } else {
    setState({
      ...state,
      [field]: e.target.value,
    });
  }
};

function camelCaseToNormalCase(inputString) {
  if (typeof inputString !== "string") {
    throw new Error("Input must be a string");
  }

  // Split the camelCase string by capital letters
  const words = inputString.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word
  const normalCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words together to form the normal case string
  const normalCaseString = normalCaseWords.join(" ");

  return normalCaseString;
}

function formatTo12HourTime(utcString) {
  const date = new Date(utcString);

  // Get hours and minutes in UTC
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Determine AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hour is 0, show 12 instead

  // Format minutes to always show two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Combine everything into the final formatted time
  return `${hours}:${formattedMinutes} ${ampm}`;
}

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const setCookies = (name, value, expiry) => {
  // let expire = new Date();
  cookies.set(name, value, { expires: expiry, path: "/" });
};

const getCookies = (name) => {
  return cookies.get(name);
};

const removeCookies = (name, options = { path: "/" }) => {
  cookies.remove(name, options);
};
const handleImage = (image) => {
  return (
    image ||
    `https://ik.imagekit.io/Yash/vecteezy_developers-troubleshooting-issues-with-a-crashed-website-of_11634404_HgCQJ9uwt.jpg?updatedAt=${new Date().getTime()}`
  );
};

const encodeForURL = (text) => {
  return text.toLowerCase().replace(/ /g, "-");
};
const parseNameforDisplay = (text) => {
  if (!text) return "";

  const parts = text.split("-");
  // Remove the last part, which is the ID
  parts.pop();
  // Capitalize the first letter of each word and join them with spaces
  const displayName = parts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
  return displayName;
};

const getIDForAPI = (text) => {
  const parts = text?.split("-");
  const id = text?.split("-")[parts.length - 1];
  return id;
};

function generateLabel(index, format = "numeric") {
  const numericLabel = (i) => i + 1;

  const romanLabel = (i) => {
    const romanNumerals = [
      "M",
      "CM",
      "D",
      "CD",
      "C",
      "XC",
      "L",
      "XL",
      "X",
      "IX",
      "V",
      "IV",
      "I",
    ];
    const values = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    let num = i + 1;
    let roman = "";

    for (let j = 0; j < values.length; j++) {
      while (num >= values[j]) {
        roman += romanNumerals[j];
        num -= values[j];
      }
    }

    return roman;
  };

  const letterLabel = (i) => {
    let result = "";
    while (i >= 0) {
      result = String.fromCharCode((i % 26) + 65) + result;
      i = Math.floor(i / 26) - 1;
    }

    return result;
  };

  switch (format) {
    case "numeric":
      return numericLabel(index);
    case "roman":
      return romanLabel(index);
    case "letter":
      return letterLabel(index);
    default:
      throw new Error('Invalid format. Use "numeric", "roman", or "letter".');
  }
}

function getCookie(name) {
  let match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
}

module.exports = {
  generateLabel,
  parseNameforDisplay,
  encodeForURL,
  getIDForAPI,
  handleImage,
  camelCaseToDashed,
  flatDeep,
  slugify,
  containsObject,
  getCategories,
  getTags,
  sortingByDate,
  sortingByLikes,
  getCourseCategories,
  toggleShowMore,
  camelCaseToNormalCase,
  inputValueChange,
  removeItemOnce,
  setCookies,
  setCookie,
  getCookies,
  getCookie,
  removeCookies,
  formatTo12HourTime,
};
